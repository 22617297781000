function loadGoogleReviews() {
  var script = document.createElement('script');
  script.defer = true;
  script.src = "//apps.elfsight.com/p/platform.js";

  var reviewsContainer = document.querySelector('.elfsight-app-7b31e7d9-b0e7-4231-91fb-075ef063766e');
  if (reviewsContainer) {
    reviewsContainer.appendChild(script);
  }
}

document.addEventListener('DOMContentLoaded', function() {
  var googleTab = document.getElementById('reviews-google-tab');
  if (googleTab) {
    googleTab.addEventListener('click', function() {
      loadGoogleReviews();
    });
  }
});


